import type { FC } from 'react';
import Button from '../../shared/components/Button/Button';
import useDpNavigate from '../../hooks/use-dp-navigate';
import { DpCta } from '../../types/digital-profile.type';
import useSegment from '../../hooks/use-segment.ts';
import { AnalyticLocation } from '../../types/analytics.type.ts';
import { descriptionCSS, fakeTooltipCSS, titleCSS } from './InfoRequestTooltip.style';
import type { InfoRequestTooltipProps } from './InfoRequestTooltip.type.ts';

const InfoRequestTooltip: FC<InfoRequestTooltipProps> = ({ customCSS, page }) => {
  const navigateToDp = useDpNavigate();
  const { track } = useSegment();

  const handleUnlockInfo = () => {
    track('Unlock Portal Data Requested', {
      page,
      location: AnalyticLocation.Chart,
    });
    navigateToDp(DpCta.Quotes);
  };

  return (
    <div css={[fakeTooltipCSS, customCSS]}>
      <h3 css={titleCSS}>We need more Info</h3>
      <p css={descriptionCSS}>Matic needs more information about your home before we can show you this graph</p>

      <Button fullWidth onClick={handleUnlockInfo}>
        Unlock this info
      </Button>
    </div>
  );
};

export default InfoRequestTooltip;
