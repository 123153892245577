import { type FC, useState } from 'react';

import Drawer from '@mui/material/Drawer';
import { NavLink, useNavigate, useParams } from 'react-router-dom';

import useAccountQuery from '../../api/account/use-account-query.ts';
import MaticLogoIcon from '../../assets/svg/matic-logo.svg?react';
import UserIcon from '../../assets/svg/user.svg?react';
import ROUTES from '../../constants/routes.ts';
import type { DefaultRouteParams } from '../../types/route-params.type.ts';
import HamburgerMenu from '../HamburgerMenu/HamburgerMenu.tsx';
import LiveHelpButton from '../LiveHelpButton/LiveHelpButton.tsx';
import useSettingsQuery from '../../api/settings/use-settings-query.ts';
import Button from '../../shared/components/Button/Button.tsx';
import ArrowLeft from '../../assets/svg/arrow-left.svg?react';
import NavigationFullscreen from './NavigationFullscreen/NavigationFullscreen.tsx';
import {
  accountButtonCSS,
  accountButtonInnerCSS,
  accountIconCSS,
  backButtonCSS,
  containerCSS,
  leftColCSS,
  logoCSS,
  menuButtonCSS,
  pageLinkCSS,
  pageLinkInnerCSS,
  pageLinksWrapperCSS,
  rightColCSS,
} from './PortalHeader.style';
import type { PortalHeaderProps } from './PortalHeader.type';

const PortalHeader: FC<PortalHeaderProps> = ({ customCSS, pageKey, showBackButton }) => {
  const { gid } = useParams() as DefaultRouteParams;
  const { data: settingsData } = useSettingsQuery();
  const { data: accountData } = useAccountQuery(gid);
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const personName = accountData?.account.first_name || 'Account';
  const phoneNumber = settingsData?.phone_number || '';
  const email = settingsData?.email || '';

  const handleDrawerToggle = () => {
    setIsOpen(s => !s);
  };

  const onBackButtonClick = () => {
    // TODO need add segment event
    navigate(-1);
  };

  return (
    <>
      <div css={[containerCSS, customCSS]}>
        <div css={leftColCSS}>
          <HamburgerMenu customCSS={menuButtonCSS} isOpen={isOpen} onClick={handleDrawerToggle} />
          <NavLink to={ROUTES.insights.replace(':gid', gid)}>
            <MaticLogoIcon css={logoCSS} />
          </NavLink>
          <nav css={pageLinksWrapperCSS}>
            <NavLink css={pageLinkCSS} to={ROUTES.insights.replace(':gid', gid)}>
              {({ isActive }) => <span css={pageLinkInnerCSS(isActive)}>Insights</span>}
            </NavLink>
            <NavLink css={pageLinkCSS} to={ROUTES.policies.replace(':gid', gid)}>
              {({ isActive }) => <span css={pageLinkInnerCSS(isActive)}>Policies</span>}
            </NavLink>
          </nav>
        </div>
        <div css={rightColCSS}>
          <LiveHelpButton pageKey={pageKey} />
          <NavLink css={accountButtonCSS} to={ROUTES.account.replace(':gid', gid)}>
            {({ isActive }) => (
              <span css={accountButtonInnerCSS(isActive)}>
                <UserIcon css={accountIconCSS} />
                {personName}
              </span>
            )}
          </NavLink>
        </div>

        <Drawer
          variant="temporary"
          open={isOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: '100%' },
          }}
        >
          <NavigationFullscreen
            gid={gid}
            personName={personName}
            phoneNumber={phoneNumber}
            email={email}
            onClose={handleDrawerToggle}
          />
        </Drawer>
      </div>

      {showBackButton
      && <Button customCSS={backButtonCSS} onClick={onBackButtonClick} variant="text"><ArrowLeft />Back</Button>}
    </>

  );
};

export default PortalHeader;
