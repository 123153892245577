import { Link, useParams } from 'react-router-dom';
import type { Replacements } from '../shared/components/DynamicTextWithReplacement/DynamicTextWithReplacement.type';
import ROUTES from '../constants/routes';
import type { DefaultRouteParams } from '../types/route-params.type';
import EXTERNAL_URLS from '../constants/external-urls';

export function useDynamicTextReplacements(): Replacements {
  const { gid } = useParams() as DefaultRouteParams;

  return {
    credit_score_details: <Link to={ROUTES.creditScoreDetails.replace(':gid', gid)}>Read more</Link>,
    fema_whats_covered: <Link to={EXTERNAL_URLS.floodSmart} target="_blank">Read more</Link>,
  };
}
