import type { Quote } from '../../../types/quote.type.ts';
import { QuoteKind, QuotingStatus } from '../../../types/quote.type.ts';
import { QuoteStatus } from '../QuoteItem/QuoteItem.type.ts';
import type { AvailableCarrier } from '../../../types/carrier.ts';
import type { BaseQuoteItemData, OptionToRender } from './CarrierOptionsList.type.ts';

export function getFullCarriersList(
  availableCarriersData?: { carriers: AvailableCarrier[] },
  quotesData?: { quotes: Quote[] },
): AvailableCarrier[] {
  if (!availableCarriersData) {
    return [];
  }

  const availableCarrierKeys = availableCarriersData.carriers.map(carrier => carrier.key);
  const missedQuoteCarriers: AvailableCarrier[] = [];

  if (quotesData) {
    quotesData.quotes.forEach((quote) => {
      if (!availableCarrierKeys.includes(quote.carrier.key)) {
        missedQuoteCarriers.push(quote.carrier);
      }
    });
  }

  return [...availableCarriersData.carriers, ...missedQuoteCarriers];
}

export function getQuotesMap(quotes?: Quote[]) {
  if (!quotes) {
    return {};
  }
  return quotes?.reduce<Record<string, BaseQuoteItemData>>((acc, quote) => {
    acc[quote.carrier.key] = {
      gid: quote.gid,
      kind: quote.kind,
      premium: quote.premium,

    };
    return acc;
  }, {});
}

export function getQuoteStatus(
  quotingStatus?: QuotingStatus,
  quoteData?: BaseQuoteItemData,
  dpCompleted?: boolean,
  carrier?: AvailableCarrier,
): QuoteStatus {
  switch (true) {
    case quoteData?.kind === QuoteKind.NonIntegrated:
      return QuoteStatus.Manual;
    case !dpCompleted || !quotingStatus:
      return QuoteStatus.Unknown;
    case !carrier?.supported:
      return QuoteStatus.Failed;
    case quotingStatus === QuotingStatus.Pending:
      return quoteData?.premium ? QuoteStatus.Ready : QuoteStatus.Pending;
    case quotingStatus === QuotingStatus.Finished:
      return quoteData?.premium ? QuoteStatus.Ready : QuoteStatus.Failed;
    default:
      return QuoteStatus.Unknown;
  }
}

export const QUOTE_STATUS_PRIORITY: Record<QuoteStatus, number> = {
  [QuoteStatus.Unknown]: 1,
  [QuoteStatus.Ready]: 2,
  [QuoteStatus.Pending]: 3,
  [QuoteStatus.Manual]: 4,
  [QuoteStatus.Failed]: 5,
};

export function addQuoteData(
  carrier: AvailableCarrier,
  quotesMap: Record<string, BaseQuoteItemData>,
  dpCompleted: boolean,
  quotingStatus?: QuotingStatus,
): OptionToRender {
  const quote = quotesMap[carrier.key];
  const quoteData = {
    gid: quote?.gid,
    premium: quote?.premium,
    status: getQuoteStatus(
      quotingStatus,
      quote,
      dpCompleted,
      carrier,
    ),
  };

  return { carrier, quote: quoteData };
}

export function sortCarriersByPremium(a?: string | number, b?: string | number) {
  const premiumA = a ? Number(a) : Infinity;
  const premiumB = b ? Number(b) : Infinity;
  return premiumA - premiumB;
}

export function sortCarriersByQuoteStatus(a: QuoteStatus, b: QuoteStatus) {
  return QUOTE_STATUS_PRIORITY[a] - QUOTE_STATUS_PRIORITY[b];
}

export function sortCarriersBySupported(a: boolean, b: boolean) {
  if (a === b) {
    return 0;
  }
  return a ? -1 : 1;
}
