import type { FC } from 'react';

import { Navigate, useLocation, useSearchParams } from 'react-router-dom';

import useSendMagicLink from '../../api/authentification/use-send-magic-link.ts';
import Illustration from '../../assets/svg/mouse-and-keyboard.svg?react';
import PageInfoContainer from '../../components/PageInfoContainer/PageInfoContainer.tsx';
import useTimer from '../../hooks/use-timer.ts';
import useAccountCheck from '../../hooks/use-account-check.ts';
import Button from '../../shared/components/Button/Button.tsx';

import ROUTES from '../../constants/routes.ts';
import useTrackPage from '../../hooks/use-track-page.ts';
import useSegment from '../../hooks/use-segment.ts';
import type { TargetPage } from '../../types/target-page.ts';
import { ANALYTICS_SESSION_GID } from '../../constants/browser-storage.ts';
import type { SendMagicLinkPayload } from '../../types/account.type.ts';
import { AnalyticPageKey } from '../../types/analytics.type.ts';
import { actionButtonCSS, illustrationCSS } from './index.style.ts';

const CheckEmailLayout: FC = () => {
  const location = useLocation();
  const email = location.state?.email;
  const [searchParams] = useSearchParams();
  const personGid = searchParams.get('person_gid');
  const maskedEmail = searchParams.get('email');
  const targetPage = searchParams.get('target_page');

  const { time, startTimer } = useTimer();
  const { mutateAsync: sendMagicLink } = useSendMagicLink();

  const { track } = useSegment();

  useTrackPage(AnalyticPageKey.CheckEmail);

  useAccountCheck(personGid, targetPage);

  const handleResendLink = async (): Promise<void> => {
    const sessionGid = localStorage.getItem(ANALYTICS_SESSION_GID);

    track('Authenticate Link Sent', {
      page: AnalyticPageKey.CheckEmail,
    });
    if (!personGid && !email) {
      // TODO: Throw Sentry error
      console.error(`No person gid=${personGid} or email=${email} found in URL`);
      return;
    }

    let params: SendMagicLinkPayload = {};

    if (personGid) {
      params = { person_gid: personGid };
    }
    else if (email) {
      params = { email };
    }

    if (targetPage) {
      params.target_page = targetPage as TargetPage;
    }

    if (sessionGid) {
      // params.session_gid = sessionGid; TODO: Uncomment after BE fix
    }

    startTimer(10);
    await sendMagicLink(params);
  };

  // TODO: Remove before v2 release
  if (window.location.host.includes('my.matic.com')) {
    return <Navigate to={ROUTES.problem} />;
  }

  return (
    <PageInfoContainer
      heading="Check your Email"
      subHeading={`We just sent a log in link to ${maskedEmail}. Click on the link to sign in.`}
    >
      <Illustration css={illustrationCSS} />
      <p>
        We&apos;ve gone password-free! Logging in is now easier and more secure. The link may take a few minutes to
        arrive, check your spam folder if you&apos;re having trouble finding it.
      </p>
      <Button css={actionButtonCSS} variant="text" disabled={time > 0} onClick={handleResendLink}>
        Resend Link
        {' '}
        {time > 0 ? time : null}
      </Button>
    </PageInfoContainer>
  );
};

export default CheckEmailLayout;
