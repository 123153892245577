import type { FC } from 'react';

import { isAxiosError } from 'axios';
import { Navigate, useParams } from 'react-router-dom';

import useAccountQuery from '../../api/account/use-account-query.ts';
import ROUTES from '../../constants/routes.ts';
import FullPageLoader from '../../shared/components/FullPageLoader/FullPageLoader.tsx';
import type { DefaultRouteParams } from '../../types/route-params.type.ts';
import type { AuthGuardProps } from './AuthGuard.type';

const AuthGuard: FC<AuthGuardProps> = ({ children, skipVerification }) => {
  const { gid } = useParams<DefaultRouteParams>();
  const { data: accountData, error } = useAccountQuery(gid, false);

  // TODO: Remove before v2 release
  if (window.location.host.includes('my.matic.com')) {
    return <Navigate to={ROUTES.problem} />;
  }

  if (error) {
    return isAxiosError(error) && error.request.status === 401
      ? (
          <Navigate to={ROUTES.signIn} />
        )
      : (
          <Navigate to={ROUTES.problem} />
        );
  }

  if (!accountData) {
    return <FullPageLoader />;
  }

  if (!skipVerification && accountData?.account && !accountData.account.verified) {
    return <Navigate to={ROUTES.verify.replace(':gid', gid as string)} />;
  }

  return <>{children}</>;
};

export default AuthGuard;
